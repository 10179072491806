body {
    font-family: 'Kanit';
}

.r-touchAction-1otgn73 {
    box-shadow: none !important;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-wrapper,
.swiper-wrapper .swiper-slide,
.my-swiper-link {
    height: 100% !important;
    cursor: pointer;
}

.my-swiper-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.DraftEditor-editorContainer {
    z-index: 0 !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 10px;
}

.admin-body::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

.admin-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1em;
}

.admin-body::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1em;
}

.admin-body {
    /* overflow-y: scroll; */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* For Firefox */
}

/* For Internet Explorer 10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .admin-body {
        -ms-overflow-style: none;
    }

    .admin-body::-ms-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }

    .admin-body::-ms-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 1em;
    }

    .admin-body::-ms-track {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 1em;
    }
}



#ReactSimpleImageViewer {
    z-index: 9999;
}

#root > div > div:nth-child(3){
    z-index: 1;
}

.meat-ball:hover {
    filter: brightness(0.5);
    cursor: pointer;
}

.recharts-tooltip-wrapper {
    z-index: 100;
}

.chat-content {
    display: flex;
    flex-direction: column-reverse ; 
    overflow: hidden !important;
    row-gap: 10px;
}

/*custom checkbox*/

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px #667080 solid;
    border-radius: 5px;
  }
  
  .container input:disabled {
    cursor: not-allowed !important;
  }
 
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
 
  .container input:disabled ~ .checkmark {
    cursor: not-allowed;
    background-color: #F0F0F0;
    border: 2px #F0F0F0 solid;

  }
  .container input:checked ~ .checkmark {
    background-color: #40506A;
    border: 2px #40506A solid;

  }

  .container input:checked ~ .checkmark:hover {
    background-color: #40506A;
    filter:brightness(0.8)
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }

